import { fetchStream } from "./fetch-stream";

export const parseStreaming = async (
  controller: AbortController,
  messages: Array<any>,
  onText: (text: string) => void,
  onError?: (status: number) => void,
) => {
  const decoder = new TextDecoder();
  let uint8Array = new Uint8Array();
  let chunks = "";
  // let sourcesEmitted = false;

  const response = await fetch(`https://api.moebh.org/chat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*./*",
    },
    signal: controller.signal,
    body: JSON.stringify({
      messages,
      // token: localStorage.getItem("token"),
    }),
  });
  if (response.status !== 200) {
    onError?.(response.status);
    return;
  }
  fetchStream(
    response,
    (chunk) => {
      uint8Array = new Uint8Array([...uint8Array, ...chunk]);
      chunks = decoder.decode(uint8Array, { stream: true });

      // console.log(chunks);
      onText(chunks);
    },
    () => {},
  );
};
