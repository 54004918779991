"use client";

import { Command } from "@/components/tailwind/ui/command";
import { useCompletion } from "ai/react";
import { useEditor } from "novel";
import { useState } from "react";
import Markdown from "react-markdown";
import { toast } from "sonner";
import CrazySpinner from "../ui/icons/crazy-spinner";
import Magic from "../ui/icons/magic";
import { ScrollArea } from "../ui/scroll-area";
import AICompletionCommands from "./ai-completion-command";
import ProofSelectorCommands from "./proof-selector-commands";

interface ProofSelectorProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ProofSelector({ open, onOpenChange }: ProofSelectorProps) {
  const { editor } = useEditor();
  const [inputValue, setInputValue] = useState("");

  const { completion, complete, isLoading } = useCompletion({
    api: "/api/proof",
    onResponse: (response) => {
      if (response.status === 429) {
        toast.error("You have reached your request limit for the day.");
        return;
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const hasCompletion = completion.length > 0;

  const handleComplete = () => {
    if (completion) {
      complete(completion, {
        body: { option: "zap", command: inputValue },
      }).then(() => setInputValue(""));
    } else {
      const slice = editor.state.selection.content();
      const text = editor.storage.markdown.serializer.serialize(slice.content);
      complete(text, {
        body: { option: "zap", command: inputValue },
      }).then(() => setInputValue(""));
    }
  };

  return (
    <Command className="w-[350px]">
      {hasCompletion && (
        <div className="flex max-h-[400px]">
          <ScrollArea>
            <div className="prose p-2 px-4 prose-sm dark:text-gray-100">
              <Markdown>{completion}</Markdown>
            </div>
          </ScrollArea>
        </div>
      )}

      {isLoading && (
        <div className="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-purple-500">
          <Magic className="mr-2 h-4 w-4 shrink-0" />
          AI正在工作
          <div className="ml-2 mt-1">
            <CrazySpinner />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          {/* <div className="relative">
            <CommandInput
              value={inputValue}
              onValueChange={setInputValue}
              autoFocus
              placeholder={hasCompletion ? "接下来要做什么" : "输入论证命令……"}
              onFocus={() => addAIHighlight(editor)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleComplete();
                }
              }}
            />
            <Button
              size="icon"
              className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full bg-purple-500 hover:bg-purple-900"
              onClick={handleComplete}
            >
              <ArrowUp className="h-4 w-4" />
            </Button>
          </div> */}
          {hasCompletion ? (
            <AICompletionCommands
              onDiscard={() => {
                editor.chain().unsetHighlight().focus().run();
                onOpenChange(false);
              }}
              completion={completion}
            />
          ) : (
            <ProofSelectorCommands onSelect={(value, option) => complete(value, { body: { option } })} />
          )}
        </>
      )}
    </Command>
  );
}

export default ProofSelector;
