export const defaultEditorContent = {
  type: "doc",
  content: [
    // {
    //   type: "heading",
    //   attrs: { level: 2 },
    //   content: [{ type: "text", text: "Cave in Sight" }],
    // },
    {
      "type": "heading",
      "attrs": {
        "level": 2
      },
      "content": [
        {
          "type": "text",
          "text": "🕳️Cave in Sight快速开始"
        }
      ]
    },
    {
      "type": "paragraph",
      "content": [
        {
          "type": "text",
          "marks": [
            {
              "type": "bold"
            }
          ],
          "text": "学术写作工具"
        },
        {
          "type": "text",
          "text": "：我们认为，研究成果分三类：理论、应用，以及纯粹的垃圾。研究者只应聚焦于真正有价值的东西。反之，廉价的内容应当直接交付机器生成。各类感想、程序型报告均如此。所以，我们推出两类工具，你可以基于自己成型的思想草稿成文，也可以直接给出题目令AI全文生成。从以下文段开始：选中内容，弹框中点击学术AI，选择基于草稿或题目构建文章。"
        }
      ]
    },
    {
      "type": "bullet_list",
      "content": [
        {
          "type": "list_item",
          "content": [
            {
              "type": "paragraph",
              "content": [
                {
                  "type": "text",
                  "text": "草稿：选中以下草稿，将其转换为文章"
                }
              ]
            },
            {
              "type": "bullet_list",
              "content": [
                {
                  "type": "list_item",
                  "content": [
                    {
                      "type": "paragraph",
                      "content": [
                        {
                          "type": "text",
                          "text": "经典和曲目的关系=方法与目的。二者的对立是因为人们之前把它看作一体的。也就是艺术，这就是勋伯格的观点：艺术与大众绝对对立（英文原文？）。但是我认为他们是客观分离的，并且不对立。之前不明显是因为发展速度慢，但是上世纪以来，理论和技法高速发展，这么快速的速度和这么多的先前理论，很自然地让音乐研究者/音乐家的的理论成果和应用成果分化/专业化。"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "type": "list_item",
          "content": [
            {
              "type": "paragraph",
              "content": [
                {
                  "type": "text",
                  "text": "题目/问题：选中以下题目，生成对应文章"
                }
              ]
            },
            {
              "type": "bullet_list",
              "content": [
                {
                  "type": "list_item",
                  "content": [
                    {
                      "type": "paragraph",
                      "content": [
                        {
                          "type": "text",
                          "text": "使用消费者行为理论，选取一个苹果公司的营销活动进行案例分析。"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "paragraph",
      "content": [
        {
          "type": "text",
          "marks": [
            {
              "type": "bold"
            }
          ],
          "text": "辅助工具"
        },
        {
          "type": "text",
          "text": "：快速转换语气、篇幅、格式、语言、语法。严格意义上，学术文章对规范性的要求繁多。以APA为例，其长达356页的“概要”版本规范性指南，对语法、无偏见语言、风格机制、参考文献等八个方面做出了详细的规定。"
        }
      ]
    },
    {
      "type": "bullet_list",
      "content": [
        {
          "type": "list_item",
          "content": [
            {
              "type": "paragraph",
              "content": [
                {
                  "type": "text",
                  "text": "学术语言改写：选中以下文段，将其用学术语言改写"
                }
              ]
            },
            {
              "type": "bullet_list",
              "content": [
                {
                  "type": "list_item",
                  "content": [
                    {
                      "type": "paragraph",
                      "content": [
                        {
                          "type": "text",
                          "text": "我觉得这段话值得细细品味。它说的是不是这样一个意思：当收入预期足够悲观，消费会非常差，储蓄会非常高，以至于降息到0也没有用，以至于货币供应再多也不会有通胀，也不会有充分就业。嗯，让我们品味一会儿，当年的日本发生了什么。"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
};
