"use client";

import { Command, CommandInput } from "@/components/tailwind/ui/command";
import { ArrowUp } from "lucide-react";
import { useEditor } from "novel";
import { addAIHighlight, removeAIHighlight } from "novel/extensions";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { Button } from "../ui/button";
import CrazySpinner from "../ui/icons/crazy-spinner";
import { ScrollArea } from "../ui/scroll-area";
import CiteSelectorCommands from "./cite-selector-commands";

interface CiteSelectorProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function CiteSelector({ open, onOpenChange }: CiteSelectorProps) {
  const { editor } = useEditor();
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]); // 搜索结果
  const [selectedPaper, setSelectedPaper] = useState<any | null>(null); // 选中的文献详细信息
  const [linkInputValue, setLinkInputValue] = useState("");

  useEffect(() => {
    if (!open) {
      removeAIHighlight(editor);
      setInputValue("");
      setSearchResults([]);
      setSelectedPaper(null);
    }
  }, [open, editor]);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      // 调用新的文献搜索API
      const response = await fetch(`/api/cite?query=${encodeURIComponent(inputValue)}`, {
        method: "GET",
      });

      if (response.status === 429) {
        toast.error("You have reached your request limit for the day.");
        setIsLoading(false);
        return;
      }

      const result = await response.json();
      setSearchResults(result); // 假设返回的数据格式为一个包含文献对象的数组
      setIsLoading(false);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSelectPaper = async (paperId: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/cite?paperId=${encodeURIComponent(paperId)}`, {
        method: "GET",
      });

      if (response.status === 429) {
        toast.error("You have reached your request limit for the day.");
        setIsLoading(false);
        return;
      }

      const result = await response.json();
      setSelectedPaper(result); // 设置选中的文献详细信息
      setIsLoading(false);
      handleInsert(result);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const handleLinkSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://api.moebh.org/coze_chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: linkInputValue }),
      });

      if (response.status === 429) {
        toast.error("You have reached your request limit for the day.");
        setIsLoading(false);
        return;
      }

      const result = await response.text(); // 处理返回的字符串
      handleInsertLinkResponse(result); // 插入从链接返回的内容
      setIsLoading(false);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const handleInsertLinkResponse = (content: string) => {
    const cleanContent = content.replace(/^"|"$/g, ''); // 去除字符串开头和结尾的引号
    const selection = editor.view.state.selection;
    editor
      .chain()
      .focus()
      .insertContentAt(selection.to + 1, cleanContent)
      .run();
    onOpenChange(false); // 关闭引用选择器
  };

  const formatAPA = (paper: any) => {
    const authors = paper.authors
      .map((author: any) => {
        const names = author.name.split(" ");
        const lastName = names.pop();
        const initials = names.map((n: string) => `${n[0]}.`).join("");
        return `${lastName}, ${initials}`;
      })
      .join(", ");

    const title = paper.title;
    const year = paper.year;
    const venue = paper.venue || "ArXiv";
    const pages = paper.pages ? `, ${paper.pages}` : "";

    return `${authors} (${year}). ${title}. *${venue}${pages}*.`;
  };

  const handleInsert = (paper: any) => {
    const formattedText = formatAPA(paper);
    const parts = formattedText.split(`${paper.venue}.`);
    const selection = editor.view.state.selection;
    editor
      .chain()
      .focus()
      .insertContentAt(selection.to + 1, parts[0])
      .setMark("italic", {})
      .insertContent(`${paper.venue}.`)
      .run();
    onOpenChange(false); // 关闭引用选择器
  };


  return (
    <Command className="w-[350px]">
      {isLoading && (
        <div className="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-purple-500">
          搜索中...
          <div className="ml-2 mt-1">
            <CrazySpinner />
          </div>
        </div>
      )}

      {!isLoading && !selectedPaper && (
        <>
          <div className="relative">
            <CommandInput
              value={inputValue}
              onValueChange={setInputValue}
              autoFocus
              placeholder="在Google Scholar中搜索文献"
              onFocus={() => addAIHighlight(editor)}
              onKeyDown={handleKeyDown}
            />
            <Button
              size="icon"
              className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full bg-blue-500 hover:bg-blue-900"
              onClick={handleSearch}
            >
              <ArrowUp className="h-4 w-4" />
            </Button>
          </div>

          {/* 新增的链接输入框 */}
          <div className="relative">
            <CommandInput
              value={linkInputValue}
              onValueChange={setLinkInputValue}
              placeholder="输入网址，生成网络文献引用"
            />
            <Button
              size="icon"
              className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full bg-green-500 hover:bg-green-900"
              onClick={handleLinkSubmit}
            >
              <ArrowUp className="h-4 w-4" />
            </Button>
          </div>

          <ScrollArea className="max-h-[200px] overflow-auto">
            {searchResults.length > 0 && (
              <div className="p-2">
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    className="cursor-pointer p-2 hover:bg-gray-200"
                    onClick={() => handleSelectPaper(result.paperId)}
                  >
                    <div className="font-semibold">{result.title}</div>
                    <div className="text-sm text-gray-500">
                      {result.authors.map((author: any) => author.name).join(", ")} - {result.year}
                    </div>
                    <div className="text-sm text-blue-500">{result.venue}</div>
                  </div>
                ))}
              </div>
            )}
          </ScrollArea>
        </>
      )}

      {!isLoading && selectedPaper && (
        <div className="p-4">
          <h2 className="text-lg font-semibold">{selectedPaper.title}</h2>
          <p className="text-sm text-gray-500">
            {selectedPaper.authors.map((author: any) => author.name).join(", ")} - {selectedPaper.year}
          </p>
          <p className="text-sm text-blue-500">{selectedPaper.venue}</p>
          <p className="mt-2">{selectedPaper.abstract}</p>
          <div className="mt-4 flex justify-end">
            <Button onClick={() => handleInsert(selectedPaper)}>插入引用</Button>
          </div>
        </div>
      )}
      <CiteSelectorCommands onSelect={(value, option) => handleInsert(value)} />
    </Command>
  );
}
