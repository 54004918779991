// components/TopBar.js
import { useEditor } from "novel";
// import SidebarLeft from "@/components/sidebar-left"; // 导入侧边栏组件
import { Button } from "@/components/tailwind/ui/button";
// import Menu from "@/components/tailwind/ui/menu";
// import { Popover, PopoverContent, PopoverTrigger } from "@/components/tailwind/ui/popover";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/tailwind/ui/dialog"
import { Input } from "@/components/tailwind/ui/input"
import { Label } from "@/components/tailwind/ui/label"
import Link from "next/link";
import { Node } from 'prosemirror-model';
import { unified } from "unified";
import markdown from "remark-parse";
import docx, { type DocxOptions } from "remark-docx";
import { useState } from "react";

export default function TopBar() {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const download = (filename, blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const exportWord = async () => {
    const content = window.localStorage.getItem("markdown");
    if (!content) return;
    // Post to server API /convert to generate Word
    // fetch("https://api.moebh.org/convert", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ text: content }),
    // })
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //   });
    const processor = unified().use(markdown).use(docx, { output: "blob" } as DocxOptions);
    const doc = await processor.process(content);
    const blob = await doc.result;
    download("novel.docx", blob);
  };

  const exportHTML = () => {
    const content = window.localStorage.getItem("html-content");
    if (!content) return;
    const blob = new Blob([content], { type: "text/html" });
    download("novel.html", blob);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.moebh.org/coze_chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: url }),
      });
      const result = await response.json();
      console.log(result);
      // 如果需要在界面上显示结果，可以将 result 存储到 state 中
    } catch (error) {
      console.error("Error generating references:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full max-w-screen-lg items-center gap-2 px-6 py-2 pb-8">
      {/* <SidebarLeft /> */}
      {/* <Menu /> */}
      {/* <Popover>
        <PopoverTrigger asChild>
          <Button variant="ghost">Group Chat (not yet)</Button>
        </PopoverTrigger>
        <PopoverContent>⌛️💬🤔</PopoverContent>
      </Popover> */}
      wechat: alpha_half
      <Button variant="link">
        <Link href="https://ex3xuh4678r.feishu.cn/share/base/form/shrcnggQYgoDnW6rhArtuhOY1rO">快速提交意见</Link>
      </Button>
      {/* <Button variant="secondary" disabled>
        一键添加参考文献
      </Button> */}
      <Button variant="ghost" onClick={exportWord}>
        导出为Word
      </Button>
      <Button variant="ghost" onClick={exportHTML}>
        导出为网站
      </Button>
      <Button variant="ghost" disabled>
        导出为演示文稿
      </Button>
      <Dialog>
      <DialogTrigger asChild>
        {/* <Button variant="outline">生成参考文献</Button> */}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>生成参考文献</DialogTitle>
          <DialogDescription>
            输入网址，生成符合APA标准的参考文献列表
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="url" className="text-right">
              网址
            </Label>
            <Input
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              // defaultValue="test"
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={handleGenerate} disabled={loading}>
              {loading ? "生成中..." : "生成"}
            </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    </div>
  );
}
