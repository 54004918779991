import { Button } from "@/components/tailwind/ui/button";
import { Input } from "@/components/tailwind/ui/input";
import { ChevronRight } from "lucide-react";
import { useState } from "react";
import Markdown from "react-markdown";
import { parseStreaming } from "./parse-streaming";

export default function AIChat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [copied, setCopied] = useState(false);
  const [locked, setLocked] = useState(false);

  const sendMessage = async () => {
    if (input.trim() === "") return;
    setLocked(true);

    const oldMessages = [...messages, { role: "user", content: input }];
    setMessages(oldMessages);
    setInput("");

    const controller = new AbortController();

    let aiMessage = "";
    try {
      await parseStreaming(
        controller,
        oldMessages,
        (chunk) => {
          aiMessage = chunk;
          setMessages([...oldMessages, { role: "assistant", content: aiMessage }]);
        },
        (status) => {
          console.error("Error rewriting text:", status);
        },
      );
      setLocked(false);
    } catch (error) {
      console.error("Error rewriting text:", error);
    }
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // 2秒后重置为初始状态
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      },
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto p-4">
        {messages.map((msg) => (
          <div key={msg.id} className={`my-2 p-2 ${msg.role === "user" ? "text-right" : "text-left"}`}>
            <Markdown className="prose dark:prose-dark">{msg.content}</Markdown>
            {msg.role === "assistant" && (
              <div>
                {/* <Button size="sm" variant="ghost" disabled>
                  添加到文档
                </Button>
                <Button className="m-2" size="sm" variant="ghost" onClick={() => copyToClipboard(msg.content)}>
                  {copied ? "已拷贝！" : "拷贝"}
                </Button> */}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="p-4">
        <div className="flex gap-2">
          <Input
            type="text"
            value={input}
            placeholder="发送消息"
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
            disabled={locked}
          />
          <Button onClick={sendMessage} variant="secondary" size="icon">
            <ChevronRight />
          </Button>
        </div>
      </div>
    </div>
  );
}
