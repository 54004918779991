"use client";

import AIChat from "@/components/aichat/chat";
import TailwindAdvancedEditor from "@/components/tailwind/advanced-editor";
import { Button } from "@/components/tailwind/ui/button";
import TopBar from "@/components/topbar";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ScrollArea } from "@/components/tailwind/ui/scroll-area";
// import { BookOpen, GithubIcon } from "lucide-react";
// import Link from "next/link";
import { useState } from "react";

export default function Page() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex min-h-screen">
      <div className="flex flex-col w-full">
        <TopBar />
        <div
          className={`flex flex-col items-center gap-4 py-4 sm:px-5 transition-all duration-300 ${
            isSidebarOpen ? "w-[60%]" : "w-[100%]"
          }`}
        >
          <h2 className="text-sm font-semibold text-gray-500">Cave in sight｜洞见学术</h2>
          <h1 className="text-2xl font-bold mt-2">学术写作AI</h1>
          <h2 className="text-md text-white-500">撰稿、改写、引用、输出</h2>
          <TailwindAdvancedEditor />
        </div>
      </div>
      <div
        className={`fixed top-0 right-0 h-full flex flex-col bg-gray-100 dark:bg-gray-900 transition-all duration-300 w-[40%] ${
          isSidebarOpen ? "" : "-mr-[40%]"
        }`}
      >
        <Button
          onClick={toggleSidebar}
          className={`fixed top-4 right-4 px-4 py-2 rounded-full shadow-lg transition-all duration-300 ${
            isSidebarOpen ? "right-[40%]" : ""
          }`}
        >
          对话
          <FontAwesomeIcon icon={isSidebarOpen ? faChevronRight : faChevronLeft} />
        </Button>
        <AIChat />
      </div>
    </div>
  );
}
