import { useEditor } from "novel";
import { removeAIHighlight } from "novel/extensions";
import {} from "novel/plugins";
import { Fragment, type ReactNode, useEffect, useState } from "react";
import { Button } from "../ui/button";
import Magic from "../ui/icons/magic";
import { AISelector } from "./ai-selector";
import { CiteSelector } from "./cite-selector"; // 引入CiteSelector组件
import EditorBubble from "./editor-bubble";
import ProofSelector from "./proof-selector"; // 引入ProofSelector组件

interface GenerativeMenuSwitchProps {
  children: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const GenerativeMenuSwitch = ({ children, open, onOpenChange }: GenerativeMenuSwitchProps) => {
  const { editor } = useEditor();
  const [citeOpen, setCiteOpen] = useState(false); // 添加新的状态
  const [proofOpen, setProofOpen] = useState(false); // 添加新的状态

  const getCurrentParagraph = () => {
    const { state } = editor;
    const { $from } = state.selection;
    const paragraphNode = $from.node($from.depth);
    const lastChar = paragraphNode.textContent;
    if (state.doc.childCount > 1 && lastChar.length > 0 && lastChar.trim() === "") {
      onOpenChange(true);
    }
  };

  useEffect(() => {
    if (editor) {
      editor.on("selectionUpdate", getCurrentParagraph);
      editor.on("transaction", getCurrentParagraph);
    }
    return () => {
      if (editor) {
        editor.off("selectionUpdate", getCurrentParagraph);
        editor.off("transaction", getCurrentParagraph);
      }
    };
  }, [editor]);

  useEffect(() => {
    if (!open) {
      removeAIHighlight(editor);
    }
  }, [open, editor]);

  useEffect(() => {
    if (!citeOpen) {
      removeAIHighlight(editor);
    }
  }, [citeOpen, editor]);

  useEffect(() => {
    if (!proofOpen) {
      removeAIHighlight(editor);
    }
  }, [proofOpen, editor]);

  const handleCancel = () => {
    setCiteOpen(false);
    setProofOpen(false);
    onOpenChange(false);
    editor.chain().focus().unsetHighlight().run();
  };

  return (
    <EditorBubble
      tippyOptions={{
        placement: open || citeOpen || proofOpen ? "bottom-start" : "top",
        onHidden: () => {
          handleCancel();
        },
      }}
      className="flex w-fit max-w-[90vw] overflow-hidden rounded-md border border-muted bg-background shadow-xl"
    >
      {open && <AISelector open={open} onOpenChange={onOpenChange} />}
      {citeOpen && <CiteSelector open={citeOpen} onOpenChange={setCiteOpen} />}
      {proofOpen && <ProofSelector open={proofOpen} onOpenChange={setProofOpen} />}
      {!open && !citeOpen && !proofOpen && (
        <Fragment>
          <Button
            className="gap-1 rounded-none text-purple-500"
            variant="ghost"
            onClick={() => onOpenChange(true)}
            size="sm"
          >
            <Magic className="h-5 w-5" />
            学术AI
          </Button>
          <Button
            className="gap-1 rounded-none text-blue-500"
            variant="ghost"
            onClick={() => setCiteOpen(true)}
            size="sm"
          >
            引用
          </Button>
          <Button
            className="gap-1 rounded-none text-blue-500"
            variant="ghost"
            onClick={() => setProofOpen(true)}
            size="sm"
            disabled
          >
            论证
          </Button>
          {children}
        </Fragment>
      )}
    </EditorBubble>
  );
};

export default GenerativeMenuSwitch;
