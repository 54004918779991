import { RefreshCcwDot } from "lucide-react";
import { useEditor } from "novel";
import { CommandGroup, CommandItem } from "../ui/command";

const options = [
  {
    value: "proof",
    label: "自动寻找论据/数据源",
    icon: RefreshCcwDot,
  },
];

interface ProofSelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
}

const ProofSelectorCommands = ({ onSelect }: ProofSelectorCommandsProps) => {
  const { editor } = useEditor();

  return (
    <>
      <CommandGroup heading="论证">
        {options.map((option) => (
          <CommandItem
            onSelect={(value) => {
              const slice = editor.state.selection.content();
              const text = editor.storage.markdown.serializer.serialize(slice.content);
              onSelect(text, value);
            }}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <option.icon className="h-4 w-4 text-purple-500" />
            {option.label}
          </CommandItem>
        ))}
      </CommandGroup>
      {/* <CommandSeparator />
      <CommandGroup heading="生成">
        <CommandItem
          onSelect={() => {
            const pos = editor.state.selection.from;

            const text = getPrevText(editor, pos);
            onSelect(text, "continue");
          }}
          value="continue"
          className="gap-2 px-4"
        >
          <StepForward className="h-4 w-4 text-purple-500" />
          续写
        </CommandItem>
      </CommandGroup> */}
    </>
  );
};

export default ProofSelectorCommands;
