import { RefreshCcwDot, StepForward } from "lucide-react";
import { useEditor } from "novel";
import { getPrevText } from "novel/utils";
import { CommandGroup, CommandItem, CommandSeparator } from "../ui/command";
import { useState } from "react";
import { toast } from "sonner";

const options = [
  {
    value: "improve",
    label: "上传PDF生成引用",
    icon: RefreshCcwDot,
  },
];

interface CiteSelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
}

const CiteSelectorCommands = ({ onSelect }: CiteSelectorCommandsProps) => {
  const { editor } = useEditor();
  const [isLoading, setIsLoading] = useState(false);

  const handlePdfUpload = async (file: File) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("https://api.moebh.org/cite_pdf", {
        method: "POST",
        body: formData,
      });

      if (response.status === 429) {
        toast.error("You have reached your request limit for the day.");
        setIsLoading(false);
        return;
      }

      const result = await response.json();
      handleInsertPdfResponse(result.text); // 假设返回的文本字段为 'text'
      setIsLoading(false);
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }
  };

  const handleInsertPdfResponse = (content: string) => {
    const selection = editor.view.state.selection;
    editor
      .chain()
      .focus()
      .insertContentAt(selection.to + 1, content)
      .run();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handlePdfUpload(file);
    }
  };

  return (
    <>
      <CommandGroup heading="基于链接/PDF引用任意文献（only APA yet）">
        {options.map((option) => (
          <CommandItem
            onSelect={() => {}}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <label htmlFor="pdf-upload" className="flex items-center gap-2 cursor-pointer">
              <option.icon className="h-4 w-4 text-purple-500" />
              {option.label}
            </label>
            <input
              id="pdf-upload"
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              className="hidden"
              disabled={isLoading}
            />
          </CommandItem>
        ))}
      </CommandGroup>
      {/* <CommandSeparator /> */}
      {/* <CommandGroup heading="生成">
        <CommandItem
          onSelect={() => {
            const pos = editor.state.selection.from;

            const text = getPrevText(editor, pos);
            onSelect(text, "continue");
          }}
          value="continue"
          className="gap-2 px-4"
        >
          <StepForward className="h-4 w-4 text-purple-500" />
          续写
        </CommandItem>
      </CommandGroup> */}
    </>
  );
};

export default CiteSelectorCommands;
